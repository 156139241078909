jQuery( document ).ready( function( $ ) {
    var headerHeight = $('.site-header').outerHeight();
    var lowerPadding = headerHeight * 2;
    $('.contact-page').css('padding-top', lowerPadding);
    $('.contact-page').css('padding-bottom', headerHeight);
    var scroll = function () {
        let scrollpos = window.scrollY
        const header = document.querySelector(".site-header")
        const header_height = header.offsetHeight

        const add_class_on_scroll = () => header.classList.add("fade-in")
        const remove_class_on_scroll = () => header.classList.remove("fade-in")

        window.addEventListener('scroll', function() {
          scrollpos = window.scrollY;
          if (scrollpos >= header_height) {
              add_class_on_scroll()
            }
          else {
              remove_class_on_scroll()
            }
          console.log(scrollpos)
        })
    };

    var waiting = false, endScrollHandle;

    $(window).scroll(function () {
        if (waiting) {
            return;
        }
        waiting = true;

        // clear previous scheduled endScrollHandle
        clearTimeout(endScrollHandle);

        scroll();

        setTimeout(function () {
            waiting = false;
        }, 100);

        // schedule an extra execution of scroll() after 200ms
        // in case the scrolling stops in next 100ms
        endScrollHandle = setTimeout(function () {
            scroll();
        }, 200);
    });
} );